.contact-us-social {
  background-color: rgba(168, 235, 168, 0.669);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: black;
  text-align: center;

  .social-lazada-img {
    width: 50px;
    height: 50px;
    padding: 2px 2px;
    image-rendering: optimizeQuality;

    @media (max-width: 480px) {
      width: 40px;
      height: 40px;
      padding: 1px 1px;
    }
  }
  .social-shopee-img {
    width: 50px;
    height: 50px;
    padding: 2px 4px 5px 3px;
    image-rendering: optimizeQuality;

    @media (max-width: 480px) {
      width: 40px;
      height: 40px;
      padding: 1px 2px 3px 2px;
    }
  }
  .social-link {
    text-decoration: none;
    color: black;

    .social-icon {
      font-size: 50px;
      @media (max-width: 480px) {
        font-size: 40px;
      }
    }
  }
}

.sub-title {
  font-weight: 600;
  font-size: 20px;
  color: black;
}

.contact-us-company-info {
  background-color: rgba(168, 235, 168, 0.669);
  padding: 20px;
  border-radius: 10px;
  color: black;
  text-align: center;


  .company-info-text {
    font-weight: 600;
    font-size: 13px;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
  }

  .company-info-text-email {
    font-weight: 600;
    font-size: 13px;
    margin: 0;
    text-decoration: none;
    display: block;
    color: rgba(0, 0, 0, 0.85);
  }
  .icon-wrapper{
    margin: 20px 0 5px;
    .company-info-icon {
      color: rgba(0, 0, 0, 0.85);
      width: 60px;
      height: 60px;
      border: 2px solid $font-color;
      border-radius: 50%;
      padding: 10px;
    
    }
  }

}


