@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

Body{
  margin: 0;
  background-color: #eff0f5 !important;
  /* background-image: url("https://www.transparenttextures.com/patterns/asfalt-dark.png"); */

  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  /* font-family: 'Cormorant Upright', serif; */
  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}


.not-use{
    /* ff 3.6+ */
    background:-moz-linear-gradient(135deg, rgba(185, 213, 168, 1) 0%, rgba(231, 237, 231, 0.02) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(135deg, rgba(185, 213, 168, 1) 0%, rgba(231, 237, 231, 0.02) 100%);
  
    /* opera 11.10+ */
    background:-o-linear-gradient(135deg, rgba(185, 213, 168, 1) 0%, rgba(231, 237, 231, 0.02) 100%);
  
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E7EDE7', endColorstr='#B9D5A8', GradientType=1 );
  
    /* ie 10+ */
    background:-ms-linear-gradient(135deg, rgba(185, 213, 168, 1) 0%, rgba(231, 237, 231, 0.02) 100%);
  
    /* global 94%+ browsers support */
    background:linear-gradient(135deg, rgba(185, 213, 168, 1) 0%, rgba(231, 237, 231, 0.02) 100%);
  
}