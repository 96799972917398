.navbar {
  min-height: 60px;
  padding: 0;
  width: 100%;
  margin: 0 0 30px;
  background-color: white;

  .qne-logo {
    height: 60px;
    // width: 60px;
  }

  .navlink-text {
    color: $font-color;
    font-weight: 500;

    &:hover {
      color:$hover-color ;
    }

  }
  .language-btn{
    background-color: white;
    border-color: $font-color;
    color: $font-color;
    font-weight: 600;
    width: 50px;

    &:hover{
      background-color: white;
      border-color: $hover-color;
      color: $hover-color;
    }
  }
  .language-btn-center{
    @media (min-width: 992px) {
      display: none;
    }
  }
  .language-btn-right{
    @media (max-width: 991px) {
      display: none;
    }
  }
}